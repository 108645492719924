<template>
  <b-card>
    <b-card-header>
      <i class="icon-note"></i> Jaminan Emas Details
      <div class="card-header-actions">
        <a href="jamimas-request" class="card-header-action" rel="noreferrer noopener">
          <small class="text-muted">Back</small>
        </a>
      </div>
    </b-card-header>
    <br>
    <table>
      <tr>
        <td><strong>Transaction Date</strong></td>
        <td>{{ this.date }}</td>
      </tr>
      <tr>
        <td><strong>Jamimas ID</strong></td>
        <td>{{ this.id }}</td>
      </tr>
      <tr>
        <td><strong>User</strong></td>
        <td>{{ this.name }} ({{ this.email }})</td>
      </tr>
      <tr>
        <td><strong>Gold Amount - Nilai Jaminan Emas</strong></td>
        <td>{{ this.gram }}</td>
      </tr>
      <tr>
        <td><strong>Cashout - Nilai Jaminan Emas</strong></td>
        <td>{{ this.cashout }}</td>
      </tr>
      <tr>
        <td><strong>Biaya Admin</strong></td>
        <td>{{ this.admin_fee }}</td>
      </tr>
      <tr>
        <td><strong>Gold Sell Price</strong></td>
        <td>{{ this.sellingRate }}</td>
      </tr>
      <tr>
        <td><strong>Tenor</strong></td>
        <td>{{ this.tenor }}</td>
      </tr>
      <tr>
        <td><strong>Total Biaya Jaminan</strong></td>
        <td>{{ this.beforeAdminFee }}</td>
      </tr>
      <tr>
        <td><strong>Nilai Tagihan</strong></td>
        <td>{{ this.amount }}</td>
      </tr>
      <tr>
        <td><strong>Invoice No</strong></td>
        <td>{{ this.invoice_number }}</td>
      </tr>
      <tr>
        <td><strong>Tujuan Pencairan Dana</strong></td>
        <td v-if='this.account_number !== "-" || this.bank_name !== "-" || this.account_name !== "-"'>{{ this.account_number }} - {{ this.bank_name }} - {{ this.account_name }}</td>
        <td v-else>Celengan</td>
      </tr>
      <tr>
        <td><strong>Status</strong></td>
        <td v-if="this.status === 0">Sedang Diproses</td>
        <td v-else-if="this.status === 1">Berhasil</td>
        <td v-else-if="this.status === 2">Ditolak</td>
        <td v-else-if="this.status === 3">Gagal</td>
      </tr>
      <tr>
        <td><strong>Disbursement Date</strong></td>
        <td>{{ this.disbursement_date }}</td>
      </tr>
      <tr>
        <td><strong>Alasan Ditolak</strong></td>
        <td v-if="this.reason === null">-</td>
        <td v-else-if="this.reason !== null">{{ this.reason }}</td>
      </tr>
    </table>
    <br>
    <b-card-header class="mb-3" v-show="this.status !==2">
      <i class="icon-note"></i> Reject Jaminan Emas
    </b-card-header>
    <b-row class="mb-3" v-show="this.status !==2">
      <b-col sm>
        <form v-on:submit.prevent="reject">
        <b-form-group
         class="mb-3"
         label=""
         label-for="reason"
        >
            <b-form-input
             id="reason"
             type="text"
             size="md"
             v-model.lazy.trim="$v.form.reason.$model"
             :state="chkState('reason')"
             aria-describedby="reasonFeedback"
             autocomplete="given-name"
             autofocus
             placeholder="Alasan Ditolak"
            ></b-form-input>
            <b-form-invalid-feedback id="reasonFeedback" v-for="(error, index) in errors.form.reason" :key="index">
              - {{ error }} <br>
            </b-form-invalid-feedback>
        </b-form-group>
        </form>
      </b-col>
      <b-col sm="2" v-if="$can('reject_jamimas_request')">
        <b-input-group-append>
              <b-button
               size="md"
               variant="danger"
               type="submit"
               @click="reject"
              >
                Reject
              </b-button>
            </b-input-group-append>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import accounting from 'accounting'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'detail-transaction-jamimas',
  data () {
    return {
      form: {
        reason: '',
      },
      date: '',
      id: '',
      name: '',
      email: '',
      gram: '',
      cashout: '',
      sellingRate: '',
      tenor: '',
      status: '',
      beforeAdminFee: '',
      total: '',
      account_name: '',
      account_number: '',
      bank_name: '',
      admin_fee: '',
      invoice_number: '',
      amount: '',
      reason: '',
      disbursement_date: '',
      rejectModal: false,
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          reason: []
        }
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      reason: {
        required
      }
    }
  },
  created () {
    this.$http.get(`pawn-withdraw/` + this.$route.params.id)
    .then((result) => {
      const payload = result.data.data;
      this.date = payload.created_at;
      this.id = payload.pawn_number;
      this.name = payload.users.name;
      this.email = payload.users.email;
      this.gram = payload.gold_amount;
      this.amount = payload.amount;
      this.cashout = payload.cashout;
      this.sellingRate = payload.selling_rate;
      this.admin_fee = payload.admin_fee;
      this.tenor = payload.period_day;
      this.status = payload.status;
      this.beforeAdminFee = payload.amount_before_admin_fee;
      this.total = '-';
      this.account_name = payload.bank_accounts.owner_name;
      this.account_number = payload.bank_accounts.account_number;
      this.bank_name = payload.bank_list.bank_name;
      this.invoice_number = payload.invoice_number;
      this.reason = payload.reason;
      this.disbursement_date = payload.disbursement_date
    }).catch((error) => {
      if(error.response) {
        this.errors.code = error.response.status;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
      }
    })
  },
  methods: {
    formatNumber (value) {
      return 'Rp.'+accounting.formatNumber(value, 0)
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    reject () {
      this.validate()
      this.isLoading = true
      this.$http.post(`pawn-withdraw/reject/` + this.$route.params.id, {
        reason: this.form.reason
      }).then(() => {
        this.isLoading = false
        this.$swal.fire(
          'Success!',
          'Jaminan emas berhasil ditolak!',
          'success'
        ).then(() => {
          location.reload()
        })
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          this.errors.code = error.response.statu;
          this.errors.status = error.response.data.meta.code;
          this.errors.headers = error.response.headers
          if(this.errors.status == 422) {
            this.errors.message = error.response.data.meta.message
            this.errors.form.reason = this.errors.message.reason
          }else if (this.errors.status == 400) {
            this.errors.message = errror.response.data.meta.message
            this.$swal.fire(
              'Failed',
              this.errors.message,
              'error'
            ).then(() => {
              location.reload()
            })
          }else if (this.errors.status == 404) {
            this.$swal.fire(
              'Failed',
              this.errors.message,
              'error'
            ).then(() => {
              location.reload()
            })
          }
        }
      })
    }
  }
}
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
</style>
